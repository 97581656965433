import React from "react";
import {BsLinkedin, BsGithub } from "react-icons/bs";


const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        
        <a href="https://github.com/AbdelhaqMAHMOUDI"><BsGithub/></a>
      </div>
      <div>
      <a href="https://www.linkedin.com/in/abdelhaqmahmoudi/" ><BsLinkedin/> </a> 
      </div>
    </div>
  );
};

export default SocialMedia;
